import React from "react";
import { Card, Modal } from "react-bootstrap";
import { getWhatsappURL } from "utils/helpers/subscription";

const ContactUsModal = ({ show, hide }) => {
  return (
    <Modal
      show={show}
      onHide={hide}
      centered
      style={{
        zIndex: 1101,
      }}
    >
      <Modal.Header closeButton className="border-0 text-center">
        <Modal.Title className="w-100">Talk to us</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="px-4"
        style={{
          fontWeight: "600",
          color: "#2887AF",
        }}
      >
        <Card
          onClick={() => {
            window.open(getWhatsappURL());
          }}
          className="mb-3 p-3 border-0 d-flex flex-row align-items-center cursor-pointer"
        >
          <img
            src="/assets/icons/social/whatsapp.png"
            className="social-icon mx-4"
          />
          <span>WhatsApp Us</span>
        </Card>
        <Card
          onClick={() =>
            window.open(`mailto:sales@kwikpic.in?subject=Kwikpic - Talk to Us`)
          }
          className="p-3 border-0 d-flex flex-row align-items-center cursor-pointer"
        >
          <img
            src="/assets/icons/social/email.png"
            className="social-icon mx-4"
          />
          <span>E-mail Us</span>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUsModal;
